import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Input() numero;
  @Input() cvv;
  @Input() nome;
  @Input() validade;
  @Input() flip;
  bandeira = '---';
  display = {
    nome:"NOME COMPLETO",
    numero:"**** ***** **** ****",
    validade:"**/****",
    cvv:"***"
  };

  constructor() { }

  static infoCard(){
    var info = [
      {
        bandeira:'visa',
        comeco :[4],
        maxNum: [13,16],
        ccv:[3]
      },
      {
        bandeira:'mastercard',
        comeco :[5],
        maxNum: [16],
        ccv:[3]
      },
      {
        bandeira:'elo',
        comeco :[636368,438935,504175,451416,509048,509067,509049,509069,509050,509074,509068,509040,509045,509051,509046,509066,509047,509042,509052,509043,509064,509040,36297, 5067,4576,4011],
        maxNum: [16],
        ccv:[3]
      },
      {
        bandeira:'discover',
        comeco :[6011,622,64,65],
        maxNum: [16],
        ccv:[4]
      },
      {
        bandeira:'amex',
        comeco :[37],
        maxNum: [15],
        ccv:[4]
      },
      {
        bandeira:'aura',
        comeco :[50],
        maxNum: [16],
        ccv:[3]
      },
      {
        bandeira:'diners',
        comeco :[301,305,36,38],
        maxNum: [14,16],
        ccv:[3]
      },
      {
        bandeira:'jcb',
        comeco :[35],
        maxNum: [16],
        ccv:[3]
      }, 
      {
        bandeira:'hipercard',
        comeco :[384100,384140,384160,606282],
        maxNum: [13,16,19],
        ccv:[3]
      }, 
    ];
    return info;
  }  

  Bandeira(num){
    if(num == '' || num == null || num == undefined){
      this.bandeira = '---';
      return '---';
    }
    var info = CreditCardComponent.infoCard();
    var possivel = [];
    var certo = null;
    info.forEach(banco => {
      banco.comeco.forEach(inicio => {
        if(num.startsWith(inicio)){
          possivel.push({bandeira:banco.bandeira,inicio:inicio.toString()});
        }
      });
    });
    if(possivel.length == 0){
      this.bandeira = '---';
      return '---';
    }
    else{
      possivel.forEach(candidatos => {
        if(certo == null){
          certo = candidatos;
        }else{
          if(candidatos.inicio.length > certo.inicio.length){
            certo = candidatos;
          }
        }
      });
      this.bandeira = certo.bandeira;
      return certo.bandeira;
    }
  }
  
  ngOnInit() {
    
  }                

}
