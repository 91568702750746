import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-error-pagamento',
  templateUrl: './modal-error-pagamento.component.html',
  styleUrls: ['./modal-error-pagamento.component.scss']
})
export class ModalErrorPagamentoComponent implements OnInit {
  
  constructor(public bsModalRef: BsModalRef) {   }
  msg: string;
  ngOnInit() {
    console.log(this.bsModalRef)
  }
}
