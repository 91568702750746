import { Component, OnInit } from '@angular/core';
import { RequestComponent } from 'src/app/Helper/request/request.component';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  constructor(public sanitizer: DomSanitizer) { }

  ambiente = RequestComponent.returnEnv();
 
  img ={
    novacana:'https://www.novacana.com/images/nc/logomenu-nc.svg',
    biodiesel:'https://www.biodieselbr.com/biodieselbr.svg',
    novacanaEvento:'https://www.novacana.com/images/nc/logomenu-nc.svg'
  }
  redirect = {
    novacana:'https://www.novacana.com',
    biodiesel:'https://www.biodieselbr.com',
    novacanaEvento:'https://www.novacana.com'
  }
  favicon = {
    novacana:'https://www.novacana.com/favicon.ico',
    biodiesel:'https://www.biodieselbr.com/favicon.ico',
    novacanaEvento:'https://www.novacana.com/favicon.ico'
  }
  title = {
    novacana:'Novacana Pagamento',
    biodiesel:'Biodieselbr Pagamento',
    novacanaEvento:'Novacana Pagamento'
  }

  ngOnInit() {
  }

}
