import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { RequestComponent } from './Helper/request/request.component';
import { AlertModule } from 'ngx-bootstrap';
import { PagamentoComponent } from './Components/pagamento/pagamento.component';
import { FormsModule } from '@angular/forms';
import { FormComponent } from './Helper/form/form.component';
import {NgxMaskModule} from 'ngx-mask'
import { FlipModule } from 'ngx-flip';
import { CreditCardComponent } from './Helper/credit-card/credit-card.component';
import { MatSelectModule, } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule} from '@angular/material';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ModalErrorPagamentoComponent } from './Modal/modal-error-pagamento/modal-error-pagamento.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule ,BsModalRef } from 'ngx-bootstrap/modal';
import { SucessoComponent } from './Components/sucesso/sucesso.component';
import { WINDOW_PROVIDERS  } from './Helper/window/window.component';
import { HeaderComponent } from './Components/header/header.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ErrorComponent } from './Components/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RequestComponent,
    PagamentoComponent,
    FormComponent,
    CreditCardComponent,
    ModalErrorPagamentoComponent,
    SucessoComponent,
    HeaderComponent,
    ErrorComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    FlipModule,
    NgxMaskModule.forRoot(),
    AlertModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    NgbModule,
    MatCheckboxModule,
    MatSelectModule   ,
    RecaptchaV3Module
  ],
  entryComponents:[ModalErrorPagamentoComponent],
  providers: [ RequestComponent,WINDOW_PROVIDERS,{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lfr-bMUAAAAAI4KNsu6YDmPt-mL5QOn-gAS9Jbh' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
