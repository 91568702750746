import { Component, OnInit } from '@angular/core';
import { RequestComponent } from 'src/app/Helper/request/request.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private request : RequestComponent , private route: ActivatedRoute , private router: Router , public sanitizer: DomSanitizer) { }

  

  ngOnInit() {
    var ambiente = RequestComponent.returnEnv();
    var  redirect = {
      novacana:'https://www.novacana.com/assinar',
      biodiesel:'https://www.biodieselbr.com/assinar',
      novacanaEvento:'https://www.novacana.com/assinar'
    }

    window.location.href  = (ambiente=='novacana'?redirect.novacana:ambiente=='biodieselbr'?redirect.biodiesel:redirect.novacanaEvento) ;
  }

}
