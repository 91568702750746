import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WINDOW } from 'src/app/Helper/window/window.component';
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})

export class RequestComponent implements OnInit {
  static config ={
    novacana:'pag.novacana.com',
    biodieselbr:'pag.biodieselbr.com'
  }
  static host = window.location.hostname;
  static baseUrl;
  
  constructor(private http: HttpClient, @Inject(WINDOW) private window: Window) {  }
  public get(url,obj,callback){
    //preparando para criar url
    var fistElement = true;
    var arrayObj  = Object.entries(obj);
    var urlFinal = null;
    if(url.includes('http')){
      console.log('######externo########')
       urlFinal = url;
    }else{
       urlFinal = RequestComponent.connectionUrl()+url;
    }
    
    arrayObj.forEach(element => {
      if(fistElement){
        fistElement = !fistElement;
        urlFinal = urlFinal+'?'+element[0]+'='+element[1];
      }
      else{
        urlFinal = urlFinal+'&'+element[0]+'='+element[1];
      }
    });
    console.log(urlFinal);
    this.http.get(urlFinal).subscribe((data) => {
      callback(data);
    },error =>{
      callback(error);
    });
  }

  public post(url,obj,callback){
    //preparando para criar url
    var urlFinal = RequestComponent.connectionUrl()+url
    this.http.post(urlFinal,obj).subscribe((data) => {
      callback(data);
    },error =>{
      callback(error);
    });
  }

  ngOnInit() {
    
  }
  
  static returnEnv(){
    switch (RequestComponent.host) {
      case RequestComponent.config.novacana:
        return "novacana";
        break;
      case RequestComponent.config.biodieselbr:
        return "biodieselbr";
        break;
      default:
        return 'default';
        break;
    }
  }
  
  static connectionUrl(){
    switch (RequestComponent.host) {
      case RequestComponent.config.novacana:
        return "https://apipag.novacana.com";
        break;
      case RequestComponent.config.biodieselbr:
        return "https://apipag.biodieselbr.com";
        break;
      default:
        return 'https://apipageventos.novacana.com';
        break;
    }
  }




}
