  import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


//telas 
import { HomeComponent } from './Components/home/home.component';
import { PagamentoComponent } from './Components/pagamento/pagamento.component';

import { SucessoComponent } from './Components/sucesso/sucesso.component';
import { ErrorComponent } from './Components/error/error.component';

const routes: Routes = [
  { path: 'finalizar', component: PagamentoComponent },
  { path: 'sucesso', component: SucessoComponent },
  { path: 'error', component: ErrorComponent },
  { path: '', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})



export class AppRoutingModule { }
