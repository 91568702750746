import { Component, OnInit } from '@angular/core';
import { RequestComponent } from 'src/app/Helper/request/request.component';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-sucesso',
  templateUrl: './sucesso.component.html',
  styleUrls: ['./sucesso.component.scss']
})
export class SucessoComponent implements OnInit {
  info;
  constructor(private sanitizer:DomSanitizer) { }
  ambiente = RequestComponent.returnEnv();
  url = RequestComponent.connectionUrl();
  urlfinal ;
  msgRetorno;
  ngOnInit() {
     this.info = JSON.parse(localStorage.getItem('payment'));
     this.urlfinal = this.sanitizer.bypassSecurityTrustUrl(this.url+'/api/download?boletoId='+this.info.boletoId);
     console.log(this.info);
     try {this.msgRetorno = JSON.parse(this.info.msg);}
     catch(e){this.msgRetorno = {cartao:'Item pago com sucesso!',boleto:'A compra só será finalizada até 2 dias uteis depois do pagamento !'}}
     console.log(this.msgRetorno);
    if(this.info.tipo =='cartao'){
      this.msgRetorno = this.msgRetorno.cartao;
    }else{
      this.msgRetorno = this.msgRetorno.boleto;
    }


    }
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
