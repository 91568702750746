import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestComponent } from 'src/app/Helper/request/request.component';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { idLocale, BsModalService } from 'ngx-bootstrap';
import { CreditCardComponent } from 'src/app/Helper/credit-card/credit-card.component';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalErrorPagamentoComponent } from 'src/app/Modal/modal-error-pagamento/modal-error-pagamento.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service'
import * as $ from 'jquery';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})



export class PagamentoComponent implements OnInit {
  public modalRef: BsModalRef;
  flip = false;
  subscription: Subscription;
  closeResult: string;
  ativo='cartao';
  hoverCartao = false;
  hoverBoleto = false;
  date;
  ambiente = RequestComponent.returnEnv();
  token = this.route.snapshot.paramMap.get("token");
  cupom;
  pedidoId;
  lido = false;
  recatchaToken = '';
  termosContrato = {
    novacana:'https://www.novacana.com/nc/contrato-adesao',
    biodieselbr:'https://www.biodieselbr.com/biodieselbr/br/contrato',
    novacanaEventos:'https://www.novacana.com/2018/inscricao'
  };
  termoslgpd = {
    novacana:'https://www.novacana.com/nc/termo-de-consentimento-de-acordo-com-a-lei-geral-de-protecao-de-dados-lgpd',
    biodieselbr:'https://www.biodieselbr.com/biodieselbr/br/termo-de-consentimento-de-acordo-com-a-lei-geral-de-protecao-de-dados-lgpd',
    novacanaEventos:'https://www.novacana.com/nc/termo-de-consentimento-de-acordo-com-a-lei-geral-de-protecao-de-dados-lgpd'
  };
  contnatosPagamento = {
    novacana:'assinatura@novacana.com',
    biodieselbr:'assinatura@biodieselbr.com',
    novacanaEventos:'assinatura@novacana.com'
  };
  iconePagamento = {
    novacana:'https://www.novacana.com/favicon.ico',
    biodieselbr:'https://www.biodieselbr.com/favicon.ico',
    novacanaEventos:'https://www.novacana.com/favicon.ico'
  };
  tituloPagamento = {
    novacana:'Pagamento Novacana',
    biodieselbr:'Pagamento Biodieselbr',
    novacanaEventos:'Pagamento Novacana'
  };


  user = {
    city:null,
    street2:null,
    name_f:null,
    name_l:null,
    email:null,
    plain_password:null,
    phone:null,
    mobile:null,
    empresa:null,
    documento:null,
    street:null,
    numero:null,
    state:null,
    zip:null,
    vencimentoboleto:null
  };
  errorForm = {
    city:false,
    street2:false,
    name_f:false,
    name_l:false,
    email:false,
    plain_password:false,
    phone:false,
    mobile:false,
    empresa:false,
    documento:false,
    street:false,
    numero:false,
    state:false,
    zip:false,
    c_nome:false,
    c_numero:false,
    c_validade:false,
    c_cvv:false,
    lido:false
  }

  cartao = {
    nome:null,
    numero:null,
    validade:null,
    cvv:null,
    parcela:1
  }
  error = [];
  produto = null;
  carregando = false;
  formaPagamento = "cartao";

  constructor(private recaptchaV3Service: ReCaptchaV3Service,private route: ActivatedRoute,private router: Router,private request : RequestComponent,public modalService: BsModalService,private spinner: NgxSpinnerService) { }
  callbaclsendme(){}
  
  sendme(){
    var data2 = {
      "token":this.token,
      "user":this.user,
    }
    this.request.post('/api/notify',data2,this.callbaclsendme);
  }

  returnClass(obj){
    var retonro = '';
    if(obj == 'cartao'){
       retonro = ' btn-div pt-2  pb-2 row justify-content-center '+this.ambiente;
      if(this.ativo ==obj){
        retonro = retonro+' active';
      }else{
        if(this.hoverCartao){
          retonro = retonro+' hover';
        }
      }
    }
    if(obj == 'boleto'){
       retonro = ' btn-div  pt-2  pb-2 row justify-content-center '+this.ambiente;
      if(this.ativo ==obj){
        retonro = retonro+' active';
      }else{
        if(this.hoverBoleto){
          retonro = retonro+' hover';
        }
      }
    }
    return retonro;
  }

  callbaclUser=(e)=>{
    console.log(e);
    if(e.status == "error"){
      var ambiente = RequestComponent.returnEnv();
      var  redirect = {
        novacana:'http://www.novacana.com/assinar',
        biodiesel:'https://www.biodieselbr.com/assinar',
        novacanaEvento:'http://www.novacana.com/assinar'
      }

      window.location.href  = (ambiente=='novacana'?redirect.novacana:ambiente=='biodieselbr'?redirect.biodiesel:redirect.novacanaEvento) ;
    }
    if(e.user  != undefined && e.user.email != undefined ){
      this.user = e.user;
      var novadata = new Date(this.user.vencimentoboleto);
      novadata.setDate( novadata.getDate() + 1 );
      var hj = new Date();
   
      if(novadata >= hj){
        this.date = novadata;
      }
    }
    this.produto =e.product;
    this.pedidoId =e.pedidoId;
    this.cupom =e.cupom;
  }

  pagamento(e){
    this.formaPagamento = e;
  }
  handleToken(tk){
    console.log('trocou tk');
    this.recatchaToken = tk;
  }
  ngOnInit() {
    localStorage.clear();
    this.date = new Date();
    this.date.setDate( this.date.getDate() + 2 );

    
    var url = this.router.url
    if(url.indexOf('/finalizar#') != -1){
      this.token =  decodeURIComponent(url.replace("/finalizar#", ""));
      console.log(this.token);
    
      this.request.post('/api/auth',{"token":this.token},this.callbaclUser);
    }
    else{
      var ambiente = RequestComponent.returnEnv();
      var  redirect = {
        novacana:'http://www.novacana.com/assinar',
        biodiesel:'https://www.biodieselbr.com/assinar',
        novacanaEvento:'http://www.novacana.com/assinar'
      }

      window.location.href  = (ambiente=='novacana'?redirect.novacana:ambiente=='biodieselbr'?redirect.biodiesel:redirect.novacanaEvento) ;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => this.handleToken(token));
    const source = interval(120000);
    const text = 'Your Text Here';
    this.subscription = source.subscribe(val =>  this.recaptchaV3Service.execute('importantAction').subscribe((token) => this.handleToken(token)));

    
      //this.router.navigate(['/']);

    
  }
  print(){
    console.log(this.user);
  }
  callbaclCep=(e)=>{
    console.log(e);
    this.user.city = e.localidade;
    this.user.street2 = e.bairro;
    this.user.street = e.logradouro;
    this.user.state = e.uf;
  }
  changeCep(cep){
    if(cep.length >=8){
      if(this.user.street == undefined || this.user.street == ''){
        if(cep != undefined && cep != null && cep != ''){
          this.request.get('https://viacep.com.br/ws/'+cep+'/json/',{},this.callbaclCep);
        }
      }
    }
  }


   verify(){
     var errorLocal = [];
    //nome
    if(this.user.name_f == undefined || this.user.name_f.length <= 1){
      this.errorForm.name_f = true;
      errorLocal.push('Nome não pode ser vazio.');
    }
    else{
      this.errorForm.name_f = false;
    }
    //sobrenome
    if(this.user.name_l == undefined || this.user.name_l.length <= 1){
      this.errorForm.name_l = true;
      errorLocal.push('Sobrenome não pode ser vazio.');
    }
    else{
      this.errorForm.name_l = false;
    }
    //sobrenome
    if(this.user.email == undefined || this.user.email.length <= 1){
      this.errorForm.email = true;
      errorLocal.push('Email não pode ser vazio.');
    }
    else{
      this.errorForm.email = false;
    }
    //sobrenome
    if(this.user.plain_password == undefined || this.user.plain_password.length <= 1){
      this.errorForm.plain_password = true;
      errorLocal.push('Senha não pode ser vazia.');
    }
    else{
      this.errorForm.plain_password = false;
    }
    //sobrenome
    if(this.user.phone == undefined || this.user.phone.length <= 1){
      this.errorForm.phone = true;
      errorLocal.push('Telefone não pode ser vazio.');
    }
    else{
      this.errorForm.phone = false;
    }
    //sobrenome
    if(this.ativo == 'boleto'){
      if(this.user.documento == undefined || this.user.documento.length <= 1){
        this.errorForm.documento = true;
        errorLocal.push('Documento não pode ser vazio.');
      }
      else{
        this.errorForm.documento = false;
      }
    }
    
    //sobrenome
    if(this.user.street == undefined || this.user.street.length <= 1){
      this.errorForm.street = true;
      errorLocal.push('Endereço não pode ser vazio.');
    }
    else{
      this.errorForm.street = false;
    }
    //sobrenome
    if(this.user.numero == undefined || this.user.numero.length < 1){999999
      this.errorForm.numero = true;
      errorLocal.push('Número não pode ser vazio.');
    }
    else{
      this.errorForm.numero = false;
    }
    //sobrenome
    if(this.user.state == undefined || this.user.state.length <= 1){
      this.errorForm.state = true;
      errorLocal.push('Estado não pode ser vazio.');
    }
    else{
      this.errorForm.state = false;
    }
    //sobrenome
    if(this.user.city == undefined || this.user.city.length <= 1){
      this.errorForm.city = true;
      errorLocal.push('Cidade não pode ser vazio.');
    }
    else{
      this.errorForm.city = false;
    }
    //sobrenome
    if(this.user.zip == undefined || this.user.zip.length <= 1){
      this.errorForm.zip = true;
      errorLocal.push('Cep não pode ser vazio.');
    }
    else{
      this.errorForm.zip = false;
    }
    if(this.ativo == 'cartao'){
      if(this.cartao.nome == undefined || this.cartao.nome.length <= 1){
        this.errorForm.c_nome = true;
        errorLocal.push('Nome do cartão não pode ser vazio.');
      }
      else{
        this.errorForm.c_nome = false;
      }
      if(this.cartao.numero == undefined || this.cartao.numero.length <= 1){
        this.errorForm.c_numero = true;
        errorLocal.push('Numero do cartão não pode ser vazio.');
      }
      else{
        this.errorForm.c_numero = false;
      }

      if(this.cartao.validade == undefined || this.cartao.validade.length <= 1){
        this.errorForm.c_validade = true;
        errorLocal.push('Validade do cartão não pode ser vazio.');
      }
      else{
        this.errorForm.c_validade = false;
      }

      if(this.cartao.cvv == undefined || this.cartao.cvv.length <= 1){
        this.errorForm.c_cvv = true;
        errorLocal.push('CVV do cartão não pode ser vazio.');
      }
      else{
        this.errorForm.c_cvv = false;
      }
    }
    if(this.lido == false){
      this.errorForm.lido = true;
      errorLocal.push('Confirmar leoitura');
    }else{
      this.errorForm.lido = false;
    }
    this.error = errorLocal;
      console.log(errorLocal);
      if(errorLocal.length ==0 && this.lido == true){
        if(this.ativo == 'boleto'){
          var data = {
            "token":this.token,
            "user":this.user,
            "product":this.produto.product_id,
            "cupom":this.cupom,
            "pedidoId": this.pedidoId,
            "recatchaToken":this.recatchaToken
          }
          this.carregando = true;
          this.request.post('/api/boleto',data,this.callbaclBoleto);
          this.spinner.show();
        }
        if(this.ativo == 'cartao'){

          var pre = this.cartao.validade.substring(0, 2);
          var ppos = this.cartao.validade.substring(2, 6);
          this.cartao.validade = pre+'/'+ppos;
          var data2 = {
            "token":this.token,
            "user":this.user,
            "product":this.produto.product_id,
            "cartao":this.cartao,
            "cupom":this.cupom,
            "pedidoId": this.pedidoId,
            "recatchaToken":this.recatchaToken
          }
          this.carregando = true;
          this.request.post('/api/cartao',data2,this.callbaclCartao);
          this.spinner.show();
        }
      }

   }
   callbaclCartao=(e)=>{
    this.spinner.hide();
    if(e.status =='success'){
      var obj ={
        tipo : "cartao",
        msg : this.produto.comment,
        url : e.boleto
      };
      localStorage.setItem('payment', JSON.stringify(obj));
      this.router.navigate(['/sucesso']);
   
    }else{
      this.recaptchaV3Service.execute('importantAction').subscribe((token) => this.handleToken(token));
      if(e.retry != undefined && e.retry == true){
        window.location.href  = '/error';
      }
      else{
        const initialState = {
          backdrop: 'static',
          centered: true,
          msg:e.msg
        };
        this.modalRef = this.modalService.show(ModalErrorPagamentoComponent,{initialState});
      }
     
    }



   }

   callbaclBoleto=(e)=>{
     this.spinner.hide();
     if(e.status =='success'){
      var obj ={
        tipo : "boleto",
        msg : this.produto.comment,
        boletoId:e.boleto
      };
      localStorage.setItem('payment', JSON.stringify(obj));
      this.router.navigate(['/sucesso']);

     }else{
      this.recaptchaV3Service.execute('importantAction').subscribe((token) => this.handleToken(token));
      if(e.retry != undefined && e.retry == true){
        window.location.href  = '/error';
      }else{
        const initialState = {
          backdrop: false,
          centered: true,
          msg:e.msg,
          
        };
  
        this.modalRef = this.modalService.show(ModalErrorPagamentoComponent,{initialState});
  
        console.log(this.modalRef);
      }
      
     }

   }
}
